import { render, staticRenderFns } from "./index.vue?vue&type=template&id=926e7d72&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/var/www/html/fantini/frontend-2/components/spinner/index.vue').default,Block: require('/var/www/html/fantini/frontend-2/components/block/index.vue').default,BlocksWrapper: require('/var/www/html/fantini/frontend-2/components/blocks-wrapper/index.vue').default,Page: require('/var/www/html/fantini/frontend-2/components/page/index.vue').default})
